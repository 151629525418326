import React, { useCallback, useContext } from 'react'
import classNames from 'classnames'

import useTranslation from '../../hooks/useTranslation'
import css from './styles.module.scss'
import { asText } from '../../utils/prismic'
import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const Company = ({ company, isFeatured, isNew, related }) => {
  const { t } = useTranslation()
  const [, dispatch] = useContext(StateContext)
  const onClick = useCallback(() => {
    dispatch({
      type: 'openCompanyModal',
      updates: { data: { ...company, related } },
    })
  }, [company, dispatch])

  return (
    <div className={css.companyWrap}>
      {company?.name && (
        <button
          className={classNames(css.companyButton, {
            [css.isFeatured]: isFeatured,
          })}
          onClick={onClick}
          key={company.meta.id}
          type="button"
        >
          <span className="sr-only">view details:</span>
          {isFeatured ? (
            <span className={css.logoWrap}>
              <img src={company.logoWhite?.url} alt={asText(company.name)} />
            </span>
          ) : (
            <span className={css.companyName}>
              {asText(company.name)}
              {isNew && <span className={css.new}>{t('new')}</span>}
            </span>
          )}
        </button>
      )}
    </div>
  )
}

export default Company
