import React from 'react'

import { asText } from '../utils/prismic'
import BodySlice from './BodySlice'
import TextSlice from './TextSlice'
import CompaniesSlice from './CompaniesSlice'
import FaqSlice from './FaqSlice'
import InfoBlocksSlice from './InfoBlocksSlice'
import Quote from './Quote'
import CaseStudiesSlice from './CaseStudiesSlice'
import HomeNewsList from '../containers/HomeNewsList'
import PartnersSlice from './PartnersSlice'

// component
// ==========================================================================================
const Slices = ({ slices, groupedArticles }) => {
  if (!Array.isArray(slices)) return null

  const mapedSlices = slices.map((slice, i) => {
    const key = asText(slice.primary.heading) || i
    switch (slice.type) {
      case 'body':
        return <BodySlice {...slice} key={key} />

      case 'text':
        return <TextSlice {...slice} key={key} />

      case 'industry':
        return (
          <CompaniesSlice
            {...slice}
            index={i}
            groupedArticles={groupedArticles}
            key={key}
          />
        )

      case 'topic':
        return <FaqSlice {...slice} key={key} />

      case 'info_blocks':
        return <InfoBlocksSlice {...slice} key={key} />

      case 'blockquote':
        return <Quote {...slice} key={key} />

      case 'case_studies':
        return <CaseStudiesSlice {...slice} key={key} />

      case 'news':
        return <HomeNewsList {...slice} key={key} />

      case 'partners':
        return <PartnersSlice {...slice} key={key} />

      default:
        return null
    }
  })

  return mapedSlices
}

export default Slices
