import React from 'react'

import css from './styles.module.scss'
import NewsItemVariation from '../NewsItemVariation'

// component
// ==========================================================================================
const NewsList = ({ articles }) => {
  return (
    <section className={css.wrap}>
      <div className="row">
        {articles.map(({ node } = {}) => (
          <NewsItemVariation {...node} key={node.meta.id} />
        ))}
      </div>
    </section>
  )
}

export default NewsList
