import React from 'react'
import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import css from './styles.module.scss'
import { ReactComponent as Arrow } from '../../images/arrow-button.svg'
import { linkResolver } from '../../utils/linkResolver'

// component
// ==========================================================================================
const ButtonLink = ({ url, meta, target, children, title, className }) => {
  const [ref, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  })
  const rel = target === '_blank' ? 'noopener noreferrer' : ''
  const content = (
    <>
      <span className={css.label}>{children}</span>
      <span className={css.circle} aria-hidden>
        <Arrow className={css.arrow} />
      </span>
    </>
  )

  if (!url && !meta) {
    return null
  }

  return (
    <div
      ref={ref}
      className={classNames(css.wrap, className, {
        [css.appear]: inView,
      })}
    >
      {meta ? (
        <GatsbyLink to={linkResolver(meta)} title={title} className={css.link}>
          {content}
        </GatsbyLink>
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <OutboundLink
          href={url}
          {...{ target, rel, title }}
          className={css.link}
        >
          {content}
        </OutboundLink>
      )}
    </div>
  )
}

export default ButtonLink
