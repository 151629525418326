import React from 'react'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import css from './styles.module.scss'
import Wysiwyg from '../Wysiwyg'

// component
// ==========================================================================================
const Accordion = ({ question, answer, i, setExpanded, isOpen }) => {
  return (
    <div className={classNames(css.accordionWrap)}>
      <motion.div
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
        className={classNames('biggest', css.question)}
        role="button"
        aria-controls={`section${i}`}
        aria-expanded={isOpen}
        id={`accordion${i}`}
      >
        {question}
      </motion.div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
                transform: 'translate3d(0, -16px, 0)',
              },
              collapsed: {
                opacity: 0,
                height: 0,
                transform: 'translate3d(0, 0, 0)',
              },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            className={css.answer}
            role="region"
            id={`section${i}`}
            aria-labelledby={`accordion${i}`}
          >
            <Wysiwyg className={css.answer} content={answer} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Accordion
