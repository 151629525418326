import React from 'react'

import css from './styles.module.scss'
import NewsList from '../NewsList'
import NewsListVariation from '../NewsListVariation'
import Title from '../Title'
import ButtonLink from '../ButtonLink'

// component
// ==========================================================================================
const HomeNewsSection = ({
  heading,
  articles = [],
  ctaLabel,
  ctaLink,
  variation,
  removeLastUnderline,
}) => {
  if (!articles.length) {
    return null
  }

  return (
    <section className={css.wrap}>
      {!!articles.length && (
        <>
          {heading && (
            <div className={css.titleWrap}>
              <div className="row">
                <div className="col-sm-12 col-md-10 offset-md-1">
                  <Title content={heading} tag="h2" />
                </div>
              </div>
            </div>
          )}
          {variation ? (
            <NewsListVariation articles={articles} />
          ) : (
            <NewsList
              articles={articles}
              removeLastUnderline={removeLastUnderline}
            />
          )}
        </>
      )}
      {ctaLabel && ctaLink && (
        <div className={css.ctaWrap}>
          <div className="row">
            <div className="col-sm-12 col-md-10 offset-md-1">
              <ButtonLink {...ctaLink} className={css.link}>
                {ctaLabel}
              </ButtonLink>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default HomeNewsSection
