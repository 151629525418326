import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Wysiwyg from '../Wysiwyg'
import Company from './Company'
import Accordion from './Accordion'

// component
// ==========================================================================================
const CompaniesSlice = ({ primary, companies, groupedArticles, index }) => {
  if (!primary || !companies) {
    return null
  }

  const { heading, body } = primary
  const featuredCompanies = []
  const nonFeaturedCompanies = []

  companies.forEach((item) => {
    if (item.isFeatured && item.company?.logoWhite) {
      featuredCompanies.push(item)
    } else {
      nonFeaturedCompanies.push(item)
    }
  })

  return (
    <section className={classNames(css.wrap)}>
      <div className="row">
        <div className="col-sm-12 col-md-7 col-lg-3 offset-md-1">
          <div className={css.titleWrap} id={`section${index + 1}`}>
            <Title tag="h5" content={heading} />
            <Wysiwyg content={body} />
          </div>
        </div>
        <div className="col-sm-12 col-md-10 col-lg-6 offset-md-1">
          <div className={css.featuredWrap}>
            {featuredCompanies.map((item, i) => (
              <Company
                {...item}
                related={groupedArticles[item.company?.meta?.id]}
                key={item.company?.meta?.uid || i}
                isFeatured
              />
            ))}
          </div>
          {!!nonFeaturedCompanies.length && (
            <Accordion>
              <div className={css.nonFeaturedWrap}>
                {nonFeaturedCompanies.map((item, i) => (
                  <Company
                    {...item}
                    related={groupedArticles[item.company?.meta?.id]}
                    key={item.company?.meta?.uid || i}
                    isFeatured={false}
                  />
                ))}
              </div>
            </Accordion>
          )}
        </div>
      </div>
    </section>
  )
}

export default CompaniesSlice
