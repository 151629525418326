import React, { useState } from 'react'

import css from './styles.module.scss'
import Title from '../Title'
import Accordion from './Accordion'

// component
// ==========================================================================================
const BodySlice = ({ primary: { heading }, faq }) => {
  const [expanded, setExpanded] = useState(-1)
  const filteredFaq = faq.filter(({ question, answer }) => question && answer)

  if (filteredFaq.length === 0) {
    return null
  }

  return (
    <section className={css.wrap}>
      <div className="row">
        <div className="col-sm-12 col-md-10 offset-md-1">
          <div className={css.inner}>
            <Title tag="h2" content={heading} />
            {filteredFaq.map((item, i) => (
              <Accordion
                {...item}
                i={i}
                setExpanded={setExpanded}
                isOpen={expanded === i}
                key={item.question}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BodySlice
