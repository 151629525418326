/* eslint-disable react/no-array-index-key */
import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Wysiwyg from '../Wysiwyg'

// component
// ==========================================================================================
const InfoBlocksSlice = ({ primary, fields }) => {
  if (!primary || !fields) return null
  // const hasCta = link && linkLabel
  // const hasImg = image1Sharp || image2Sharp

  return (
    <section className={classNames(css.wrap)}>
      <div className="row">
        <div className="col-sm-12 col-md-9 offset-md-1">
          <div className={css.titleWrap}>
            <Title tag="h2" content={primary.heading} />
          </div>
        </div>
      </div>
      <div className="row">
        {fields.map(({ block }, i) => (
          <div className="col-sm-12 col-md-9 col-lg-4 offset-md-1" key={i}>
            <div className={classNames(css.contentBlock, css[`block${i}`])}>
              <Wysiwyg content={block} />
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default InfoBlocksSlice
