import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

import css from './styles.module.scss'
import useTranslation from '../../hooks/useTranslation'
import TextButton from '../TextButton'

// component
// ==========================================================================================
const Accordion = ({ children }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const toggleAccordion = useCallback(() => {
    setExpanded(!expanded)
  }, [expanded])

  return (
    <div className={classNames(css.accordionWrap)}>
      <AnimatePresence>
        {expanded && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            className={css.drawer}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div initial={false} className={css.triggerWrap}>
        <TextButton onClick={toggleAccordion} isOnDarkBackground>
          {t(expanded ? 'showLess' : 'showMore')}
        </TextButton>
      </motion.div>
    </div>
  )
}

export default Accordion
