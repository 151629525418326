import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Wysiwyg from '../Wysiwyg'
import Separator from '../Separator'

// component
// ==========================================================================================
const TextSlice = ({ primary }) => {
  if (!primary) {
    return null
  }

  return (
    <>
      <section className={classNames(css.wrap)}>
        <div className="row">
          <div className="col-sm-12 col-md-9 col-lg-8 offset-md-1">
            <div className={css.wysiwygWrap}>
              <Wysiwyg content={primary.body} className={css.body} />
            </div>
          </div>
        </div>
      </section>
      <Separator />
    </>
  )
}

export default TextSlice
