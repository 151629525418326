import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Wysiwyg from '../Wysiwyg'
import Image from '../Image'

// component
// ==========================================================================================
const BodySlice = ({ primary, fields }) => {
  if (!primary) {
    return null
  }

  const { heading, body } = primary

  return (
    <section className={classNames(css.wrap)}>
      <div>
        <div className="row">
          <div className="col-sm-12 col-md-9 col-lg-4 offset-md-1 position-static">
            <div className={classNames(css.titleWrap, {})}>
              <Title tag="h3" content={heading} />
            </div>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-5 offset-md-1">
            {!!fields.length && (
              <div className={css.imagesWrap}>
                {fields.map(({ logo }) => (
                  <div className={css.imageWrap} key={logo?.url}>
                    <Image image={logo} />
                  </div>
                ))}
              </div>
            )}
            <div className={classNames(css.wysiwygWrap, 'body-wysiwyg')}>
              <Wysiwyg content={body} className="bigger" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BodySlice
