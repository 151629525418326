import React from 'react'

import css from './styles.module.scss'
import ColorChangingBackground from '../ColorChangingBackground'

// component
// ==========================================================================================
const Quote = ({ primary: { quote, reference, referenceTitle } = {} }) => {
  return quote ? (
    <ColorChangingBackground className={css.wrap}>
      <div className="row">
        <div className="col-sm-12 col-lg-8 offset-lg-2">
          <blockquote>
            <h3>{quote}</h3>
            <footer>
              <div className={css.reference}>{reference}</div>
              <div className={css.title}>{referenceTitle}</div>
            </footer>
          </blockquote>
        </div>
      </div>
    </ColorChangingBackground>
  ) : null
}

export default Quote
