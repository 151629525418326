import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'

// component
// ==========================================================================================
const Separator = ({ smallTop }) => {
  return <hr className={classNames(css.rule, { [css.smallTop]: smallTop })} />
}

export default Separator
