import React, { useContext, useEffect } from 'react'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

import css from './styles.module.scss'
import { StateContext } from '../../context/GlobalStateContext'

const THRESHOLD = 0.5

// component
// ==========================================================================================
const ColorChangingBackground = ({ className, children, noFadeOut }) => {
  const [, dispatch] = useContext(StateContext)
  const [ref, inView, entry] = useInView({
    threshold: THRESHOLD,
  })
  let reveal = false

  if (entry) {
    const triggeredByBottomEdge = entry.boundingClientRect.top < 0
    const overrideFadeout = Boolean(
      triggeredByBottomEdge && noFadeOut && entry.intersectionRatio < THRESHOLD
    )
    // dont fade out when leaving bottom edge if overridden
    reveal = inView || overrideFadeout
  }

  useEffect(() => {
    dispatch({
      type: 'setColorChangingBackgroundActive',
      updates: { isActive: reveal },
    })
  }, [dispatch, reveal])

  return (
    <section
      className={classNames(css.wrap, className, {
        [css.reveal]: reveal,
      })}
    >
      <div className={css.backgroundWrap} ref={ref}>
        <div className={classNames(css.background)} />
      </div>
      <div className={classNames(css.contentWrap)}>{children}</div>
    </section>
  )
}

export default ColorChangingBackground
