import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Link from '../Link'
import { ReactComponent as Arrow } from '../../images/arrow-right.svg'

// component
// ==========================================================================================
const Separator = ({ company, link, logo, description }) => {
  return (
    company && (
      <Link link={link} className={classNames(css.itemWrap)}>
        <span className={css.itemLogo} aria-hidden>
          {logo ? (
            <img src={logo.url} alt={logo.alt || ''} />
          ) : (
            <span className={css.logoPlaceholder} />
          )}
        </span>
        <span className={classNames(css.itemCompany, 'biggest')}>
          {company}
        </span>
        <span className={classNames(css.itemDescription, 'bigger')}>
          {description}
        </span>
        <Arrow className={css.arrow} />
      </Link>
    )
  )
}

export default Separator
