import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Image from '../Image'
import Link from '../Link'

// component
// ==========================================================================================
const NewsItemVariation = ({
  meta,
  type,
  title,
  author,
  eventDate,
  photo,
  photoSharp,
  externalLink,
}) => {
  const isExternal = externalLink?.url
  const link = externalLink?.url ? externalLink : { meta }

  return (
    <div className="col-sm-12 col-md-6">
      <article className={css.wrap}>
        <Link link={link} className={css.link}>
          <div className={classNames(css.row, 'row')}>
            <div className="col-sm-12">
              <div className={css.imageWrap}>
                <Image
                  image={photo}
                  {...photoSharp}
                  className={classNames(css.image, {
                    [css.nonSharpImage]: !photoSharp,
                  })}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className={css.type}>{type}</div>
              <Title
                tag="h4"
                content={title}
                className={classNames(css.title, { 'out-arrow': isExternal })}
              />
              {type?.toLowerCase() === 'event'
                ? eventDate && (
                    <div className={classNames(css.eventData, 'biggest')}>
                      {eventDate}
                    </div>
                  )
                : author && <div className={css.author}>By {author}</div>}
            </div>
          </div>
        </Link>
      </article>
    </div>
  )
}

export default NewsItemVariation
