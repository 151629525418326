import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import { get } from 'lodash'

import HomeNewsSection from '../components/HomeNewsSection'
import css from './homeNewsList.module.scss'
import useTranslation from '../hooks/useTranslation'
import { prismicLocales } from '../translations/config'

// data
// ==========================================================================================
const query = graphql`
  query HomeNewsListQuery($lang: String) {
    prismic {
      insight: allNewss(
        lang: $lang
        sortBy: publication_date_DESC
        where: { type: "insight" }
      ) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
            }
            title
            author
            type
            externalLink: external_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            photo
            # photoSharp {
            #   childImageSharp {
            #     fluid(maxWidth: 900) {
            #       ...GatsbyImageSharpFluid_withWebp_noBase64
            #     }
            #   }
            # }
          }
        }
      }
      event: allNewss(lang: $lang, where: { type: "event", featured: true }) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
            }
            title
            author
            type
            eventDate: event_date
            eventAddress: event_address
            externalLink: external_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            photo
            # photoSharp {
            #   childImageSharp {
            #     fluid(maxWidth: 900) {
            #       ...GatsbyImageSharpFluid_withWebp_noBase64
            #     }
            #   }
            # }
          }
        }
      }
      update: allNewss(
        lang: $lang
        sortBy: publication_date_DESC
        where: { type: "update" }
      ) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
            }
            title
            author
            type
            externalLink: external_link {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            photo
            # photoSharp {
            #   childImageSharp {
            #     fluid(maxWidth: 900) {
            #       ...GatsbyImageSharpFluid_withWebp_noBase64
            #     }
            #   }
            # }
          }
        }
      }
    }
  }
`

// component
// ==========================================================================================
const Head = ({ prismic, primary }) => {
  const { locale } = useTranslation()
  const lang = prismicLocales(locale)
  const articles = [
    get(prismic, 'update.edges', []).find(
      ({ node }) => node.meta.lang === lang
    ),
    get(prismic, 'insight.edges', []).find(
      ({ node }) => node.meta.lang === lang
    ),
  ]
  let events = get(prismic, 'event.edges', []).find(
    ({ node }) => node.meta.lang === lang
  )

  if (events && !Array.isArray(events)) {
    events = [events]
  }

  return (
    <>
      <HomeNewsSection
        heading={primary.newsHeading}
        articles={articles}
        variation
        {...primary}
      />
      <HomeNewsSection
        heading={primary.eventHeading}
        articles={events}
        removeLastUnderline
      />
      <div className={css.hrWrap}>
        <div className="row">
          <div className="col-sm-12 col-md-10 offset-md-1">
            <hr className={css.hr} />
          </div>
        </div>
      </div>
    </>
  )
}

// static query
// ==========================================================================================
const HeadWithQuery = (props) => (
  <StaticQuery
    query={query}
    render={withPreview(
      (data) => (
        <Head {...data} {...props} />
      ),
      query
    )}
  />
)

export default HeadWithQuery
