/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'

// component
// ==========================================================================================
const ButtonLink = ({
  children,
  onClick,
  className,
  isOnDarkBackground,
  disabled,
}) => {
  if (!children || !onClick) {
    return null
  }

  return (
    <button
      className={classNames(css.button, className, {
        [css.onDark]: isOnDarkBackground,
      })}
      type="button"
      {...{ onClick, disabled }}
    >
      {children}
    </button>
  )
}

export default ButtonLink
