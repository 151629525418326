import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'

import css from './styles.module.scss'
import Title from '../Title'
import ColorChangingBackground from '../ColorChangingBackground'
import ButtonLink from '../ButtonLink'
import ListItem from './ListItem'

// component
// ==========================================================================================
const CompaniesSlice = ({ primary, caseStudies }) => {
  const filteredCaseStudies = useRef([])
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  useEffect(() => {
    filteredCaseStudies.current = caseStudies.filter(
      ({ company, link }) => company && link
    )
  }, [caseStudies])

  return (
    <ColorChangingBackground className={css.wrap}>
      <div className="row">
        <div className="col-sm-12 col-md-10 col-lg-6 offset-md-1">
          <div className={css.titleWrap}>
            <Title tag="h2" content={primary.heading} />
            {/* <Wysiwyg content={body} /> */}
          </div>
        </div>
      </div>
      <div className="row" ref={ref}>
        <div className="col-sm-12 col-md-10 offset-md-1">
          <div className={classNames(css.listWrap, { [css.appear]: inView })}>
            {filteredCaseStudies.current.map((item) => (
              <ListItem {...item} key={item.link?.meta?.id} />
            ))}
          </div>

          <ButtonLink {...primary.ctaLink}>{primary.ctaLabel}</ButtonLink>
        </div>
      </div>
    </ColorChangingBackground>
  )
}

export default CompaniesSlice
