import React from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import Wysiwyg from '../Wysiwyg'
import CircleImage from '../CircleImage'
import ButtonLink from '../ButtonLink'

// component
// ==========================================================================================
const BodySlice = ({ primary }) => {
  if (!primary) return null
  const {
    heading,
    body,
    link,
    linkLabel,
    image1Sharp,
    image2Sharp,
    sticky,
  } = primary
  const hasCta = link && linkLabel
  const hasImg = image1Sharp || image2Sharp

  return (
    <section className={classNames(css.wrap)}>
      <div
        className={classNames({
          [css.textWrapWithCta]: hasCta,
          [css.hasImg]: hasImg,
        })}
      >
        <div className="row">
          <div className="col-sm-12 col-md-9 col-lg-4 offset-md-1 position-static">
            <div
              className={classNames(css.titleWrap, {
                [css.sticky]: sticky,
              })}
            >
              <Title tag="h3" content={heading} />
            </div>
            {hasCta && (
              <div className={css.ctaWrap}>
                <ButtonLink {...link}>{linkLabel}</ButtonLink>
              </div>
            )}
          </div>
          <div className="col-sm-12 col-md-9 col-lg-5 offset-md-1">
            <div className={classNames(css.wysiwygWrap, 'body-wysiwyg')}>
              <Wysiwyg content={body} className="bigger" />
            </div>
          </div>
        </div>
      </div>
      {hasImg && (
        <div className={css.imageWrap}>
          <div className="row">
            <div className="col-5 offset-1">
              {image1Sharp && (
                <div
                  className={classNames(css.image2Wrap, {
                    [css.hasCta]: hasCta,
                  })}
                >
                  <CircleImage
                    image={image2Sharp}
                    position="bottomRight"
                    className={css.image2}
                  />
                </div>
              )}
            </div>
            <div className="col-sm-10 col-md-4 offset-md-1">
              {image1Sharp && (
                <div className={css.image1Wrap}>
                  <CircleImage
                    image={image1Sharp}
                    position="topRight"
                    className={css.image1}
                    d={0.05}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default BodySlice
